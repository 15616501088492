/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

const UseIgToken = (code) => {
    const navigate = useNavigate();
    const allowedCode = code ? code.replace("#_", "") : null;
    const instaFormData = new FormData();
    instaFormData.append("client_id", "298858528962321");
    instaFormData.append("client_secret", "215ef9c830adabe4a1f3279de2175b6b");
    instaFormData.append("grant_type", "authorization_code");
    instaFormData.append("redirect_uri", "https://priffle.soycale.com/igToken");

    const getIgToken = async () => {
        try {
        instaFormData.append("code", allowedCode);
        const res =  await fetch('https://api.instagram.com/oauth/access_token',
        {
            method: 'POST',
            headers: { 
                Origin: "https://priffle.soycale.com/"
            },
            body: instaFormData
        }).then(response => {
            if (!response.ok) {
                throw Error(response.statusText)
            
              }
           return response.text()
        }).then( data => JSON.parse(data));
        console.info("RESPONDE HERE", res);
        if(res){
            localStorage.setItem('ig_user_token', res.access_token || '');
            localStorage.setItem('ig_user_id', res.user_id || '');
            navigate('/userProfile', { replace: true });
        } else {
            navigate('/login', { replace: true });
        }
    } catch (error) {
            console.info('ERROR FETCHING IG TOKEN', error)
        }
    }
    useEffect(() => {
        if(allowedCode){
            localStorage.setItem('ig_user_code', allowedCode || '');
            getIgToken();
            // navigate('/userProfile', { replace: true });
        } else {
            navigate('/login', { replace: true });
        }
    }, [allowedCode]);
}

export default UseIgToken                                                         