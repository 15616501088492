import styled from 'styled-components';

export const CardContainer = styled.div.attrs({
  className: `flex m-4 p-2 w-64 justify-center items-end`,
})``;

export const ImgContainer = styled.div.attrs({
  className: ``,
})`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 300px;
`;
