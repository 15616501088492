/* eslint-disable react/prop-types */
import React from 'react';

import { openNoFrame } from './setRoutes';
import Pages from './activePages';

export const PageRouter = (pageProps) => {
  const orderedRoutes = Pages.map((page) => {
        return openNoFrame({
            ...page,
            pageProps,
        });
    });
  console.info('orderedRoutes', orderedRoutes);
  return <>{orderedRoutes}</>;
};
