import React from 'react';
// import UseLogIn from "./hooks";
import { ViewContLogin, LoginButton } from "./styles";
const LoginPage = () => {
    return(
        <div className="container mx-auto h-screen">
            <div className="flex flex-col items-center flex-wrap">
                <div className="flex flex-col items-center mt-5">
                    <p className="text-xl text-blue-600 italic font-semibold">Log in</p>
                </div>
                <ViewContLogin>
                    <p className="text-sm font-medium text-center">To continue using our services we need you to accept some terms, please click for the following steps.</p>
                    {/* <LonginInput
                        placeholder="User Name" 
                        type={"text"}
                        value={userInfo.userName} 
                        onChange={(e) => {
                            setUserInfo({...userInfo, userName: e.target.value})
                        }}/>
                    <LonginInput 
                        placeholder="Password"
                        type={"text"} 
                        value={userInfo.password}  
                        onChange={(e) => {
                            setUserInfo({...userInfo, password: e.target.value})
                        }}/> */}
                    {/* <LoginButton onClick={fetchIg}>Login</LoginButton> */}
                    {/* <a className='w-max bg-red-600 iitems-center justify-center' href="https://api.instagram.com/oauth/authorize?client_id=298858528962321&redirect_uri=https://priffle.soycale.com/igToken&scope=user_profile,user_media&response_type=code"> */}
                    <LoginButton onClick={() => {window.location.replace('https://api.instagram.com/oauth/authorize?client_id=298858528962321&redirect_uri=https://priffle.soycale.com/igToken&scope=user_profile,user_media,instagram_graph_user_media&response_type=code')}}
                        >Next</LoginButton>
                    {/* </a> */}
                </ViewContLogin>
            </div>
        </div>
    )
}

export default LoginPage