/* eslint-disable no-unused-vars */
import React from 'react';
import { Route } from 'react-router-dom';

export const openNoFrame = ({
  name,
  path,
  component: Component,
  exact,
  pageProps,
}) => {
  return (
  <Route
    path={path}
    exact={exact}
    element={<Component {...{ pageProps}}/>}
    key={name}
  />
)};
