import React from 'react';
import UseIgProfile from './hooks';
import { ImgContainer } from './styles';
const IgProfile = () => {
    const [{ userIgProfile, userIgProfileMedia }] = UseIgProfile();
    return (
        <div className="container h-screen mx-auto w-full">
            <div className='px-2'>
            <p className='text-lg font-semibold'>Profile: {userIgProfile.username || 'Re check info'}</p>
            </div>
            <p className='text-center font-medium text-lg mt-3'> {userIgProfile.media_count} Publicaciones</p>
            <hr className='h-1'/>
            <div className='sm:flex sm:justify-center'>
            <div className='flex flex-wrap bg-gray-100 justify-center sm:w-1/2 mt-5'>
                {userIgProfileMedia?.data?.length > 0 &&
                 userIgProfileMedia?.data?.map(img => {
                    return(
                        <div className="container flex flex-col max-w-xs rounded-lg overflow-hidden shadow-lg m-2 bg-white hover:shadow-xl cursor-pointer transform hover:scale-105 duration-500 ease-in-out">
                        <ImgContainer backgroundImage={img.media_url}/>
                        {img?.caption && (<p className='ml-3 text-sm mt-2 font-light'>{userIgProfile.username} : {img.caption}</p>)}
                        </div>
                    )
                })}
            </div>
            </div>
        </div>
    )
}

export default IgProfile;