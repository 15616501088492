import { useLocation } from 'react-router-dom';
import UseIgToken from './hooks';
const IgToken = () => {
    const params = useLocation();
    // const navigate = useNavigate();
    const code = new URLSearchParams(params.search).get('code');
    UseIgToken(code);
    return(
        <div>Loaging</div>
    )
}

export default IgToken