import { atom } from 'recoil';

export const userIgProfileState = atom({
  key: 'userIgProfileState',
  default: {},
});

export const userIgProfileMediaState = atom({
    key: 'userIgProfileMediaState',
    default: {},
  });