import { Routes } from 'react-router-dom';
import { PageRouter as Pages } from '../navigation';

const Layout = () => {
    console.info('PAGES', Pages);
    return(
        <Routes>
            {Pages()}
        </Routes>
    )
}

export default Layout;