import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="text-3xl font-bold underline text-green-300">
         Wait for it.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Future Priffle
        </a>
        <p className="text-lg font-bold underline text-green-300">
         V1.2
        </p>
      </header>
    </div>
  );
}

export default App;
