import LoginScreen from '../pages/login';
import IgProfile from '../pages/igProfile';
import AppFile from '../appFile';
import IgToken from '../components/IgToken';
const Pages = [
    {
        component: LoginScreen,
        exact: true,
        name: 'Login',
        path: '/login',
        height: '24px',
        side: 'left',
    },
    {
        component: IgProfile,
        exact: true,
        name: 'IgProfile',
        path: '/userProfile',
        height: '24px',
        side: 'left',
    },
    {
        component: AppFile,
        exact: true,
        name: 'defualt',
        path: '/',
        height: '24px',
        side: 'left',
    },
    {
        component: IgToken,
        exact: true,
        name: 'defualt',
        path: '/igToken',
        height: '24px',
        side: 'left',
    },
]

export default Pages;