import { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { userIgProfileState, userIgProfileMediaState } from './state';
const UseIgProfile = () => {
    const igToken = localStorage.getItem('ig_user_token');
    const uriProfile = `https://graph.instagram.com/me?fields=id,username,media_count,media&access_token=${igToken}`;
    const mediaIgUri = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,children&access_token=${igToken}`
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [userIgProfile, setUserIgProfile] = useRecoilState(userIgProfileState);
    const [userIgProfileMedia, setUserIgProfileMedia] = useRecoilState(userIgProfileMediaState);

    // const [, setIsLoadingData] = useState(false);
    const fetchIgProfile = async () => {
        try {
            setIsLoadingData(true);
            const res = await fetch(uriProfile, {
                method: 'GET'
            }).then(response => {
                // if (!response.ok) {
                //     throw Error(response.statusText)
                //   }
               return response.text()
            }).then( data => JSON.parse(data));
            console.info('RES', res);
            setUserIgProfile(res);
        } catch (error) {
            console.info('ERROR FETCHING IG INGO', error);       
        }finally{
            setIsLoadingData(false);
        }
    };
    const fetchIgProfileMedia = async () => {
        try {
            setIsLoadingData(true);
            const res = await fetch(mediaIgUri, {
                method: 'GET'
            }).then(response => {
                // if (!response.ok) {
                //     throw Error(response.statusText)
                //   }
               return response.text()
            }).then( data => JSON.parse(data));
            console.info('RES MEDIA', res);
            setUserIgProfileMedia(res);
        } catch (error) {
            console.info('ERROR FETCHING IG INGO', error);       
        }finally{
            setIsLoadingData(false);
        }
    };
    useEffect(() => {
        if(Object.keys(userIgProfile).length === 0){
            console.info('Data loaded for profile')
            fetchIgProfile();
        }        
        if(Object.keys(userIgProfileMedia).length === 0){
            console.info('Data loaded for media')
            fetchIgProfileMedia();
        }   
    });

    return [{isLoadingData, userIgProfile, userIgProfileMedia}, { setUserIgProfile }];
}

export default UseIgProfile;