import styled from 'styled-components';

export const ViewContLogin = styled.div.attrs({
  className: `flex flex-col items-center justify-center bg-white m-5 h-72 w-96 shadow-2xl border-2 border-gray-200 rounded-md px-7`,
})``;

export const LonginInput = styled.input.attrs({
    className: `border-2 border-gray-400 rounded-md h-9 px-1 w-full`,
  })`
  outline: none;
  margin-top: 10px;
  ::placeholder{
    color: rgb(128,128,128, 1);;
  };
  :focus::placeholder {
    color: rgb(128,128,128, 0.5);
  }
  `;

  export const LoginButton = styled.button.attrs({
    className: `bg-blue-500 h-9 w-1/2 mt-9 text-white text-lg rounded-md shadow-2xl transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 hover:bg-blue-600`,
    type: "button", 
})``;