import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layout';
import { RecoilRoot } from 'recoil';

const App = () => {
    return (
        <Router>
            <RecoilRoot>
                <Layout/>
            </RecoilRoot>
        </Router>
    )
}

export default App;